import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { ISSUE } from "@/constants/bread-crumbs/issue"
import { TASK } from "@/constants/bread-crumbs/task"
import { BOOLEAN } from "@/constants"
import { QUERY_OPERAND, ISSUE_TYPES } from "@/constants"

export const beforeIssue = async (to, from) => {
  const isNavigatingFromOutside         = !from.name
  const isNavigatingFromIssueChildRoute = ["issue-accesses", "issue-print"].includes(from.name)
  const isNavigatingFromIssue           = from.name === "issue"
  const isNavigatingToTask              = to.name === "task"
  const isNavigatingFromAnotherIssue    = isNavigatingFromIssue && from.params.id !== to.params.id

  const hasToLoadData = isNavigatingFromOutside ||
    isNavigatingFromAnotherIssue ||
    (!isNavigatingFromIssue && !isNavigatingFromIssueChildRoute || isNavigatingToTask)

  if (hasToLoadData) {
    store.commit("issues/setCommentAdded", false)
    store.commit("issues/setAddingComment", false)
    store.commit("issues/setCommentRemoved", false)
    store.commit("issues/setRemovingComment", false)
    store.commit("messages/setMessageAdded", false)
    store.commit("messages/setAddingMessage", false)
    store.commit("messages/setMessageRemoved", false)
    store.commit("messages/setRemovingMessage", false)
    store.commit("messageItems/setMessageItemAdded", false)
    store.commit("messageItems/setAddingMessageItem", false)
    store.commit("issues/resetUpdatingIssue")
    store.commit("issues/resetIssueUpdated")
    store.commit("reports/resetReportUpdated")
    store.commit("reports/resetUpdatingReport")
    store.commit("messages/resetMessageUpdated")
    store.commit("messages/resetUpdatingMessage")
    store.commit("messageItems/resetMessageItemUpdated")
    store.commit("messageItems/resetUpdatingMessageItem")

    const loadIssuePromise = store.dispatch("issues/loadIssue", +to.params.id)

    store.dispatch("users/loadUsers")
    store.dispatch("accessControl/loadLoggedInUserAccess", [{
      policies: [
        "Issue view",
        "Issue update",
        "Issue add comment",
        "Issue add documents",
        "Issue remove comment",
        "Issue add form instances",
        "Issue update issue field values",
        "Issue print",
        "Issue add task"
      ],
      resourceIds: [+to.params.id]
    }])

    store.dispatch("accesses/loadAccesses", {
      roleId: [2, 3, 4]
    })

    await loadIssuePromise

    let issues  = store.getters["issues/issues"]
    const issue = issues.find(issue => issue.id === +to.params.id)

    if (issue) {
      const loadIssuePromise = store.dispatch("issues/loadIssues", {
        properties: "id,summary,parentId,statusId,assigneeId"
      })
      const isACase          = issue.typeId === ISSUE_TYPES[0].id
      if (to.name === "issue" && isACase) {
        store.commit("shared/setBreadcrumbs", ISSUE(to))
        store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.CASE))
      } else {
        to.params.issueId = issue.parentId
        store.commit("shared/setBreadcrumbs", TASK(to))
        store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.TASK))
      }

      const groups   = new Set()
      const accesses = store.getters["accesses/accesses"]
      for (const access of accesses) {
        if (access.groupId) {
          groups.add(access.groupId)
        }
      }
      for (const group of groups) {
        store.dispatch("groups/loadUsers", group)
      }

      store.dispatch("issues/loadLogs", issue.id)
      store.dispatch("issues/loadComments", issue.id)

      store.dispatch("issueDocuments/loadIssueDocuments", {
        issueId: issue.id
      }).then(() => {
        const issueDocuments               = store.getters["issueDocuments/issueDocuments"]
        const issueDocumentsOfCurrentIssue = issueDocuments.filter(issueDocument =>
          issueDocument.issueId === +to.params.id
        )

        if (issueDocumentsOfCurrentIssue.length) {
          store.dispatch("accessControl/loadLoggedInUserAccess", [{
            policies   : ["IssueDocument remove"],
            resourceIds: issueDocumentsOfCurrentIssue.map(issueDocument => issueDocument.id)
          }])
        }
      })

      if (isACase) {

        loadIssuePromise.then(() => {
          issues = store.getters["issues/issues"]
          store.dispatch("accessControl/loadLoggedInUserAccess", [{
            policies: [
              "Issue add link"
            ],
            resourceIds: issues.map(issue => issue.id)
          }])
        })
        store.dispatch("domains/loadDomains")

        store.dispatch("formTemplates/loadFormTemplates", {
          reportForm: BOOLEAN.FALSE
        }).then(() => {
          const issueFormTemplates = store.getters["formTemplates/issueFormTemplates"]
          if (issueFormTemplates.length) {
            store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations", {
              formTemplateId: issueFormTemplates.map(formTemplate => formTemplate.id).toString()
            })
          }
        })

        store.dispatch("labels/loadLabels")
        store.dispatch("translations/loadTranslations", {
          issueId: issue.id
        }).then(() => {
          const translations           = store.getters["translations/translations"]
          const idsOfIssueTranslations = new Array()
          for (const translation of translations) {
            if (translation.issueId === +to.params.id) {
              idsOfIssueTranslations.push(translation.id)
            }
          }
          if (idsOfIssueTranslations.length) {
            store.dispatch("accessControl/loadLoggedInUserAccess", [{
              policies   : ["Translation update"],
              resourceIds: idsOfIssueTranslations
            }])
          }
        })

        if(store.getters["accessControl/loggedInUserPolicies"][["ReplyTemplate view"]]) {
          store.dispatch("replyTemplates/loadReplyTemplates")
        }

        store.dispatch("reports/loadReports", {
          issueId: issue.id
        }).then(() => {
          let loadFormInstancesQueryParams = { issueId: issue.id }
          const reports                    = store.getters["reports/reports"]
          const report                     = reports.find(report => report.issueId === +to.params.id)
          if (report) {
            store.dispatch("accessControl/loadLoggedInUserAccess", [{
              policies   : ["Report view", "Report add messages", "Report update", "Report content clear"],
              resourceIds: [report.id]
            }])
            store.dispatch("channels/loadChannel", report.channelId)
            store.dispatch("messages/loadMessages", {
              reportId: report.id
            }).then(() => {
              const messages            = store.getters["messages/messages"]
              const idsOfReportMessages = new Array()
              for (const message of messages) {
                if (message.reportId === report.id) {
                  idsOfReportMessages.push(message.id)
                }
              }
              store.dispatch("accessControl/loadLoggedInUserAccess", [{
                policies   : ["Message update", "Message remove"],
                resourceIds: idsOfReportMessages
              }])
              store.dispatch("messageItems/loadMessageItems", {
                messageId: idsOfReportMessages.toString()
              }).then(() => {
                const messageItems            = store.getters["messageItems/messageItems"]
                const idsOfReportMessageItems = new Array()
                for (const messageItem of messageItems) {
                  if (idsOfReportMessages.includes(messageItem.messageId)) {
                    idsOfReportMessageItems.push(messageItem.id)
                  }
                }
                store.dispatch("accessControl/loadLoggedInUserAccess", [{
                  policies   : ["MessageItem add translations"],
                  resourceIds: idsOfReportMessageItems
                }])
              })
            })

            loadFormInstancesQueryParams = {
              ...loadFormInstancesQueryParams,
              reportId: report.id,
              operand : QUERY_OPERAND.OR
            }
          }
          store.dispatch("formInstances/loadFormInstances", loadFormInstancesQueryParams)
            .then(() => {
              const formInstances           = store.getters["formInstances/formInstances"]
              const idsOfIssueFormInstances = formInstances
                .filter(formInstance => formInstance.issueId === issue.id)
                .map(formInstance => formInstance.id)

              store.dispatch("accessControl/loadLoggedInUserAccess", [{
                policies   : ["FormInstance update", "FormInstance remove"],
                resourceIds: idsOfIssueFormInstances
              }])
            })
        })

        store.dispatch("fields/loadFieldsV2")
        store.dispatch("issueFields/loadIssueFields").then(() => {
          const issueFields                     = store.getters["issueFields/issueFields"]
          const issueFieldsIds                  = issueFields.map(issueField => issueField.id).toString()
          const loadIssueFieldValuesQueryParams = {
            issueId     : issue.id,
            issueFieldId: issueFieldsIds
          }
          store.dispatch("issueFieldValues/loadIssueFieldValues", loadIssueFieldValuesQueryParams)
        })

        store.dispatch("issueLinks/loadIssueLinks", {
          fromId : issue.id,
          toId   : issue.id,
          operand: QUERY_OPERAND.OR
        }).then(() => {
          const issueLinks      = store.getters["issueLinks/issueLinks"]
          const idsOfIssueLinks = issueLinks.map(issueLink =>issueLink.id )
          if (idsOfIssueLinks.length) {
            store.dispatch("accessControl/loadLoggedInUserAccess", [{
              policies   : ["IssueLink remove"],
              resourceIds: idsOfIssueLinks
            }])
          }
        })

        store.dispatch("optionListItems/loadOptionListItems")
      }
    } else {
      return {
        name: "issues"
      }
    }

    store.dispatch("groups/loadGroups")
    store.dispatch("configurations/loadConfigurations")
    store.dispatch("dataRetentionPeriods/loadDataRetentionPeriods")
    store.dispatch("workflowAssociations/loadWorkflowAssociations")
  }
}