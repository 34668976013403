export default {
  loadingMessageItems        : undefined,
  messageItems               : new Array(),
  addingMessageItem          : false,
  messageItemAdded           : false,
  humanTranslatingMessageItem: false,
  messageItemHumanTranslated : false,
  updatingMessageItem        : {
    value: false
  },
  messageItemUpdated: {
    value: false
  },
  downloadingFile: undefined
}