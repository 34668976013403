/**
 * @file It exports a vuex store instance used by this application.
 */
import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"
import Cookies from "js-cookie"
import auth from "@/store/auth"
import shared from "@/store/shared"
import domains from "@/store/domains"
import users from "@/store/users"
import configurations from "@/store/configurations"
import channels from "@/store/channels"
import groups from "@/store/groups"
import issues from "@/store/issues"
import reports from "@/store/reports"
import organisationCodes from "@/store/organisation-codes"
import roles from "@/store/roles"
import policies from "@/store/policies"
import issueStatuses from "@/store/issue-statuses"
import issueResolutions from "@/store/issue-resolutions"
import issueDocuments from "@/store/issue-documents"
import translationPreferences from "@/store/translation-preferences"
import messages from "@/store/messages"
import messageItems from "@/store/message-items"
import languages from "@/store/languages"
import accesses from "@/store/accesses"
import exceptions from "@/store/exceptions"
import translations from "@/store/translations"
import support from "@/store/support"
import logs from "@/store/logs"
import fields from "@/store/fields"
import labels from "@/store/labels"
import kpis from "@/store/kpis"
import accessControl from "@/store/access-control"
import integrations from "@/store/integrations"
import exports from "@/store/exports"
import optionLists from "@/store/option-lists"
import optionListItems from "@/store/option-list-items"
import formTemplates from "@/store/form-templates"
import formTemplateConfigurations from "@/store/form-template-configurations"
import formInstances from "@/store/form-instances"
import automations from "../store/automations"
import issueFields from "@/store/issue-fields"
import issueFieldValues from "../store/issue-field-values"
import issueLinks from "../store/issue-links"
import issueTypes from "../store/issue-types"
import dataRetentionRules from "../store/data-retention-rules"
import dataRetentionPeriods from "../store/data-retention-periods"
import emailSubscriptions from "../store/email-subscriptions"
import issueSearch from "../store/issue-search"
import workflows from "../store/workflows"
import workflowAssociations from "../store/workflow-associations"
import transitions from "../store/transitions"
import screens from "../store/screens"
import screenItems from "@/store/screen-items"
import postFunctions from "@/store/post-functions"
import preConditions from "@/store/pre-conditions"
import replyTemplates from "@/store/reply-templates"

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    new VuexPersistence({
      restoreState: key => Cookies.getJSON(key),
      saveState   : (key, state) => {
        Cookies.set(key, state, {
          expires : null,
          domain  : window.location.host.split(":")[0],
          secure  : true,
          sameSite: "lax",
          path    : "/"
        })
      },
      modules: ["auth"]
    }).plugin
  ],
  modules: {
    shared,
    auth,
    accesses,
    domains,
    configurations,
    channels,
    users,
    groups,
    issues,
    reports,
    organisationCodes,
    roles,
    policies,
    issueStatuses,
    issueResolutions,
    issueDocuments,
    translationPreferences,
    messages,
    messageItems,
    languages,
    exceptions,
    translations,
    support,
    logs,
    fields,
    labels,
    kpis,
    accessControl,
    integrations,
    exports,
    formTemplates,
    optionLists,
    optionListItems,
    formTemplateConfigurations,
    formInstances,
    automations,
    issueFields,
    issueFieldValues,
    issueLinks,
    issueTypes,
    dataRetentionRules,
    dataRetentionPeriods,
    emailSubscriptions,
    issueSearch,
    workflows,
    workflowAssociations,
    transitions,
    screens,
    screenItems,
    postFunctions,
    preConditions,
    replyTemplates
  }
})