/**
 * @file It contains vuex issues module getter methods to access store variables.
 */
export default {
  isLoadingIssues            : state => state.loadingIssues,
  issues                     : state => state.issues,
  lastSearchIssues           : state => state.lastSearchIssues,
  isUpdatingSummary          : state => state.updatingIssue.summary,
  isSummaryUpdated           : state => state.issueUpdated.summary,
  isUpdatingDescription      : state => state.updatingIssue.description,
  isDescriptionUpdated       : state => state.issueUpdated.description,
  isUpdatingAssignee         : state => state.updatingIssue.assigneeId,
  isAssigneeUpdated          : state => state.issueUpdated.assigneeId,
  isUpdatingDomain           : state => state.updatingIssue.domainId,
  isDomainUpdated            : state => state.issueUpdated.domainId,
  isUpdatingDataRetainedUntil: state => state.updatingIssue.dataRetainedUntil,
  isDataRetainedUntilUpdated : state => state.issueUpdated.dataRetainedUntil,
  isUpdatingStatus           : state => state.updatingIssue.statusId,
  isReceivedAtUpdated        : state => state.issueUpdated.receivedAt,
  isUpdatingReceivedAt       : state => state.updatingIssue.receivedAt,
  isAcknowledgedAtUpdated    : state => state.issueUpdated.acknowledgedAt,
  isUpdatingAcknowledgedAt   : state => state.updatingIssue.acknowledgedAt,
  isStatusUpdated            : state => state.issueUpdated.statusId,
  isUpdatingLabels           : state => state.updatingIssue.labels,
  isLabelsUpdated            : state => state.issueUpdated.labels,
  isLoadingLogs              : state => state.loadingLogs,
  logsOfIssues               : state => state.logsOfIssues,
  isLoadingComments          : state => state.loadingComments,
  commentsOfIssues           : state => state.commentsOfIssues,
  isCommentAdded             : state => state.commentAdded,
  isAddingComment            : state => state.addingComment,
  isCommentRemoved           : state => state.commentRemoved,
  isRemovingComment          : state => state.removingComment,
  accessInformation          : state => state.accessInformation,
  isIssueAdded               : state => state.issueAdded,
  isAddingIssue              : state => state.addingIssue,
  aiPrefillField             : state => state.aiPrefillField,
  isLoadingAiPrefillFields   : state => state.loadingAiPrefillFields,
  isAddingAiPrefillField     : state => state.addingAiPrefillField,
  isAiPrefillFieldAdded      : state => state.aiPrefillFieldAdded
}