import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/form-template-configurations"

export default {
  /**
   * This action will load form template configurations.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load forms.
   */
  async loadFormTemplateConfigurations(context, payload) {
    context.commit("setLoadingFormTemplateConfigurations", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getFormTemplatesResult = await httpClient.get(undefined, {
      params: payload
    })

    if (getFormTemplatesResult.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateFormTemplateConfigurations", getFormTemplatesResult.data)
      } else {
        context.commit("setFormTemplateConfigurations", getFormTemplatesResult.data)
      }
    }

    context.commit("setLoadingFormTemplateConfigurations", false)
  },

  /**
   * This action will add new form template configurations.
   * @param {*} context is the store.
   * @param {*} payload contains the details of a form template to be added.
   */
  async addFormTemplateConfigurations(context, payload) {
    context.commit("setAddingFormTemplateConfigurations", true)
    context.commit("setFormTemplateConfigurationsAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postFormTemplateConfigurationsResponse = await httpClient.post(undefined, payload)

    if (postFormTemplateConfigurationsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateFormTemplateConfigurations", postFormTemplateConfigurationsResponse.data)
      context.commit("setFormTemplateConfigurationsAdded", true)
    }
    context.commit("setAddingFormTemplateConfigurations", false)
  },

  /**
   * This action updates form template configuration.
   * @param {*} context is the store.
   * @param {*} formTemplateConfiguration contains the details to be updated.
   */
  async updateFormTemplateConfiguration(context, formTemplateConfiguration) {
    const id = formTemplateConfiguration.id
    delete formTemplateConfiguration.id
    const propertiesToBeUpdated = Object.keys(formTemplateConfiguration)
    for (const property of propertiesToBeUpdated) {
      if (formTemplateConfiguration[property] === undefined) {
        formTemplateConfiguration[property] = null
      }
    }
    context.commit("setUpdatingFormTemplateConfiguration", propertiesToBeUpdated)
    context.commit("resetFormTemplateConfigurationUpdated", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateFieldResponse = await httpClient.put(`/${id}`, formTemplateConfiguration)

    if (updateFieldResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateFormTemplateConfigurations", [{
        id,
        ...formTemplateConfiguration
      }])
      context.commit("setFormTemplateConfigurationUpdated", propertiesToBeUpdated)
    }

    context.commit("resetUpdatingFormTemplateConfiguration", propertiesToBeUpdated)
  },
  /**
   * This action removes form template configuration.
   * @param {*} context is the store.
   * @param {*} payload contains the id of the form template
   */
  async removeFormTemplateConfiguration(context, payload) {
    context.commit("setRemovingFormTemplateConfiguration", true)
    context.commit("setFormTemplateConfigurationRemoved", false)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteFormTemplateConfigurationResponse = await httpClient.delete(`/${payload}`)

    if (deleteFormTemplateConfigurationResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("formTemplateConfigurationRemove", payload)
      context.commit("setFormTemplateConfigurationRemoved", true)
    }

    context.commit("setRemovingFormTemplateConfiguration", false)
  },
  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingFormTemplateConfigurations", false)
    context.commit("setFormTemplateConfigurations", new Array())
    context.commit("setAddingFormTemplateConfigurations", false)
    context.commit("setFormTemplateConfigurationsAdded", false)
    context.commit("setRemovingFormTemplateConfiguration", false)
    context.commit("setFormTemplateConfigurationRemoved", false)
    context.commit("resetUpdatingFormTemplateConfiguration")
    context.commit("resetFormTemplateConfigurationUpdated")
  }
}