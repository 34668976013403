import store from "@/plugins/vuex"
import { REPORTER_INTAKE_FORM } from "@/constants/bread-crumbs/reporter-intake-form"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to reporter intake form page.
 */
export const beforeReporterIntakeForm = async (to, from) => {

  let loadConfigurationsPromise
  if (!["reporter-intake-form", "reporter-intake-form-template-configuration"].includes(from.name)) {

    await store.dispatch("formTemplates/loadFormTemplate", +to.params.id)
    const formTemplates = store.getters["formTemplates/formTemplates"]
    const formTemplate  = formTemplates.find(optionList => optionList.id === +to.params.id)
    if (!formTemplate || !formTemplate.reportForm) {
      return "reporter-intake-forms"
    }

    store.commit("shared/setPageTitle", PAGE_TITLE_WITHOUT_TRANSLATION(formTemplate.name))
    store.commit("shared/setBreadcrumbs", REPORTER_INTAKE_FORM(to, formTemplate.name))

    store.dispatch("fields/loadFieldsV2")
    store.dispatch("optionLists/loadOptionLists")
    loadConfigurationsPromise = store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations", { formTemplateId: +to.params.id })
    store.dispatch("channels/loadChannels")

    if (to.name === "reporter-intake-form-template-configuration") {
      await loadConfigurationsPromise
    }
  }

  if (to.name === "reporter-intake-form-template-configuration") {
    const formTemplateConfigurations = store.getters["formTemplateConfigurations/formTemplateConfigurations"]
    const formTemplateConfiguration  = formTemplateConfigurations.find(configuration =>
      configuration.formTemplateId === +to.params.id &&
      configuration.id === +to.params.configurationId
    )

    if (!formTemplateConfiguration) {
      return {
        name  : "reporter-intake-form",
        params: { id: +to.params.id }
      }
    }
  }
}